import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import * as icons from '../../../assets/icons';

const ICONS = {
  'active-claims': icons.ActiveClaims,
  'previous-claims': icons.PreviousClaims,
  add: icons.AddIcon,
  addons: icons.AddOnsIcon,
  addexcess: icons.AddExcessIcon,
  alarm: icons.Alarm,
  'arrow-left': icons.ArrowLeftIcon,
  'arrow-right': icons.ArrowRightIcon,
  ban: icons.BanIcon,
  bank: icons.BankIcon,
  briefcase: icons.BriefcaseIcon,
  car: icons.CarIcon,
  card: icons.CardIcon,
  cards: icons.CardsIcon,
  chart: icons.ChartIcon,
  check: icons.CheckIcon,
  cert: icons.CertIcon,
  'chevron-down': icons.ChevronDownIcon,
  'chevron-left': icons.ChevronLeftIcon,
  'chevron-right': icons.ChevronRightIcon,
  'chevron-up': icons.ChevronUpIcon,
  child: icons.ChildIcon,
  clipboard: icons.Clipboard,
  'clipboard-with-pen': icons.ClipboardWithPen,
  clock: icons.ClockIcon,
  cog: icons.CogIcon,
  cross: icons.CrossIcon,
  code: icons.CodeIcon,
  container: icons.Container,
  dashboard: icons.DashboardIcon,
  date: icons.DateIcon,
  doc: icons.DocIcon,
  door: icons.DoorIcon,
  download: icons.DownloadIcon,
  dpolicies: icons.DPoliciesIcon,
  edit: icons.EditIcon,
  email: icons.EmailIcon,
  funnel: icons.FunnelIcon,
  heart: icons.HeartIcon,
  help: icons.HelpIcon,
  home: icons.HomeIcon,
  info: icons.InfoIcon,
  legal: icons.LegalIcon,
  link: icons.LinkIcon,
  location: icons.LocationIcon,
  lock: icons.LockIcon,
  logout: icons.LogoutIcon,
  medal: icons.MedalIcon,
  menu: icons.MenuIcon,
  mobile: icons.MobileIcon,
  moon: icons.MoonIcon,
  password: icons.PasswordIcon,
  'password-hide': icons.PasswordHideIcon,
  phone: icons.PhoneIcon,
  policies: icons.PoliciesIcon,
  policy: icons.PolicyIcon,
  postcode: icons.PostcodeIcon,
  quote: icons.QuoteIcon,
  remove: icons.RemoveIcon,
  seat: icons.Seat,
  shield: icons.ShieldIcon,
  spanner: icons.SpannerIcon,
  star: icons.StarIcon,
  'steering-wheel': icons.SteeringWheelIcon,
  sun: icons.SunIcon,
  tick: icons.TickIcon,
  time: icons.TimeIcon,
  tip: icons.TipIcon,
  user: icons.UserIcon,
  'user-card': icons.UserCardIcon,
  'user-group': icons.UserGroupIcon,
  warning: icons.WarningIcon,
  industry: icons.Industry,
  medical: icons.Medical,
  chain: icons.Chain,
  penalty: icons.Penalty,
  wallet: icons.Wallet,
  chat: icons.Chat,
  registration: icons.Registration,
  product: icons.Product,
  insurer: icons.Insurer,
  student: icons.StudentIcon,
  'year-calendar': icons.YearCalendarIcon,
  'direct-debit': icons.DirectDebitIcon,
  'paying-monthly': icons.PayingMonthlyIcon,
  stars: icons.PayingStarsIcon,
  'green-check': icons.GreenCheck,
};

export type IconNames = keyof typeof ICONS;

type IconProps = {
  /** Name of the icon to render */
  name: IconNames;
  /** The colour of the icon. Defaults to the text colour */
  color?: string;
  /** The size of the icon */
  size?: string;
  /** Custom className to pass on to svg (Can use tailwind classes) */
  className?: string;
  /** id for testing purpose only */
  testId?: string;
};

const Icon = ({
  name,
  color,
  size = '1rem',
  className,
  testId,
}: IconProps): JSX.Element => (
  <ReactSVG
    className={clsx('flex fill-current stroke-current', className)}
    style={{ color, width: size, height: size }}
    beforeInjection={svg => {
      if (size) {
        svg.setAttribute('width', size);
        svg.setAttribute('height', size);
      }
    }}
    src={ICONS[name]}
    data-testid={testId}
  />
);

export default Icon;
