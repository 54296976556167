import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ApiError, DayInsureAPI, OpenAPI } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import useNavigateWithReferrer from '../useNavigateWithReferrer';
import query from './queryKeys';

const usePolicyInfoQuery = (policyId: string | undefined, queryKey = '') => {
  const { t } = useTranslation('account');
  const navigate = useNavigateWithReferrer();

  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPolicy = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.policy.getApiV1Policy(policyId || '');
  };

  return useQuery(query.policy(policyId || '', queryKey), getPolicy, {
    keepPreviousData: false,
    retry: (count, error: ApiError) => error.status !== 404 && count < 3,
    enabled: policyId !== undefined,
    onError: err => {
      setGlobalError(err, {
        cta: t('policy_info.error_cta'),
        onClose: () => {
          navigate({
            pathname: `/policies`,
          });
        },
      });
    },
  });
};

export default usePolicyInfoQuery;
