import { LoadingSpinner, Stepper } from '@dayinsure/components';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import {
  AssetType,
  DayInsureAPI,
  LicenceType,
  OpenAPI,
  QuoteSummaryResponseIAPIResult,
  UnderWriterName,
  VehicleTransmissionType,
  VehicleType,
} from '../../../api';
import { DocumentContext } from '../../../contexts/DocumentContext/DocumentContext';
import GlobalErrorContext from '../../../contexts/GlobalErrorContext/GlobalErrorContext';
import {
  getCreateGuestAccountRequestBody,
  getCreateQuoteRequestBody,
  getCreateRegisteredKeeperQuoteRequestBody,
  getCurrentPageFromRoute,
  getInitialDataForSignedInFlow,
  getInitialDataForSignInMidFlow,
  getQuoteJourneyInitialData,
  getUpdateAccountRequestBody,
  getUpdateQuoteRequestBody,
  getValidationSchemaFromRoute,
  QuoteJourneyFormData,
  QuoteJourneyRoutes,
} from '../../../helpers/forms/quote';
import {
  customerIdentifiedEvent,
  productDetailsEvent,
  quoteCheckoutReachedEvent,
  quoteCreatedEvent,
} from '../../../helpers/gtm/events';
import inferProductName from '../../../helpers/product/inferProductName';
import useCreateAcceptanceCriteriaMutation from '../../../hooks/mutations/useAcceptanceCriteriaMutation';
import useCreateQuoteMutation from '../../../hooks/mutations/useCreateQuoteMutation';
import useUpdateQuoteMutation from '../../../hooks/mutations/useUpdateQuoteMutation';
import useBlackBoxId from '../../../hooks/useBlackBoxId';
import useNavigateWithReferrer from '../../../hooks/useNavigateWithReferrer';
import usePerson from '../../../hooks/usePerson';
import useReferrer from '../../../hooks/useReferrer';
import RouterState from '../../../types/RouterState';
import { isApiError } from '../../../types/typeguards';
import { vehicleTypeToAssetType } from '../../../types/typeMappers';
import PageContainer from '../../Layout/PageContainer/PageContainer';
import FetchingQuoteLoader from './FetchingQuoteLoader/FetchingQuoteLoader';
import QuoteSummaryCard from '../QuoteSummary/QuoteSummaryCard/QuoteSummaryCard';
import QuoteSummaryMobile from '../QuoteSummary/QuoteSummaryMobile/QuoteSummaryMobile';
import LicenceTypeDialog from './LicenceTypeDialog';
import MulsanneDialog from './MulsanneDialog/MulsanneDialog';
import ConvertedVanDialog from './ConvertedVanDialog/ConvertedVanDialog';
import AutomaticLicenceDailog from './AutomaticLicenceDialog/AutomaticLicenceDailog';
import getAddonTitle from '../CoverAddons/Addons/getAddonTitle';
import noProductAvailableForBrand from './noProductAvailableForBrand/noProductAvailableForBrand';

const getStepperProgressFromRoute = (route: string): [number, number] => {
  if (route.endsWith('registration-search')) return [0, 0.33];
  if (route.endsWith('manual-vehicle-entry')) return [0, 0.66];
  if (route.endsWith('driver-details')) return [1, 0.25];
  if (route.endsWith('contact-details')) return [1, 0.5];
  if (route.endsWith('learner-driver')) return [1, 0.75];
  if (route.endsWith('cover-length')) return [2, 0.33];
  if (route.endsWith('addons')) return [2, 0.66];
  if (route.endsWith('acceptance')) return [3, 0.33];
  if (route.endsWith('review')) return [3, 0.66];
  if (route.endsWith('pay')) return [4, 0.5];
  return [0, 0.33];
};

type JourneyProgress = {
  stepIndex: number;
  stepProgress: number;
  formPage: QuoteJourneyRoutes | undefined;
};

const QuoteJourney = () => {
  const { t } = useTranslation(['quotes', 'common']);
  const {
    person,
    marketingPreferences: personsMarketingPreferences,
    personId: currentPersonId,
    isFullAccount,
  } = usePerson();
  const location = useLocation();
  const routerState = location.state as RouterState;
  const navigate = useNavigateWithReferrer();
  const referrer = useReferrer();

  const { setGlobalError } = useContext(GlobalErrorContext);
  const { setUnderwriter, setAssetType, setProduct } = useContext(DocumentContext);

  const createQuoteMutation = useCreateQuoteMutation();
  const updateQuoteMutation = useUpdateQuoteMutation();
  const createAcceptanceCriteriaMutation = useCreateAcceptanceCriteriaMutation();

  const blackboxId = useBlackBoxId();

  const { signinSilent } = useAuth();

  const [manuallyCheckingLicence, setManuallyCheckingLicence] = useState(false);
  const [mulsanneDialogOpen, setMulsanneDialogOpen] = useState<boolean>(false);
  const [convertedVanDialogOpen, setConvertedVanDialogOpen] = useState<boolean>(false);
  const [automaticLicenceDialogOpen, setAutomaticLicenceDialogOpen] = useState(false);

  const handleCloseConertedVanDialog =
    (setFieldValue: FormikHelpers<QuoteJourneyFormData>['setFieldValue']) =>
    (isConverted: boolean) => {
      if (isConverted) {
        setFieldValue('vehicleType', {
          id: VehicleType.MOTORHOME,
          name: t('quotes:vehicle.manual.vehicle_type.motorhome'),
        });
        setFieldValue('isConvertedToMotorhome', true);
      }
      setConvertedVanDialogOpen(false);
    };

  const handleCloseAutomaticLicenceDialog = () => setAutomaticLicenceDialogOpen(false);

  const [journeyProgress, setJourneyProgress] = useState<JourneyProgress>({
    stepIndex: getStepperProgressFromRoute(location.pathname)[0],
    stepProgress: getStepperProgressFromRoute(location.pathname)[1],
    formPage: getCurrentPageFromRoute(location.pathname),
  });
  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    getValidationSchemaFromRoute(location.pathname)
  );
  const steps = useMemo(
    () => [
      t('quotes:steps.vehicle'),
      t('quotes:steps.you'),
      t('quotes:steps.cover'),
      t('quotes:steps.review'),
      t('quotes:steps.payment'),
    ],
    [t]
  );

  const [params] = useSearchParams();
  const referrerChannel =
    params.get('referrerChannel') !== '' ? params.get('referrerChannel') : '';
  useEffect(() => {
    const [stepIndex, stepProgress] = getStepperProgressFromRoute(location.pathname);
    setJourneyProgress({
      stepIndex,
      stepProgress,
      formPage: getCurrentPageFromRoute(location.pathname),
    });
    setCurrentValidationSchema(getValidationSchemaFromRoute(location.pathname));
  }, [location]);

  // ATTEMPTS TO CREATE A QUOTE
  const createQuote = async (
    values: QuoteJourneyFormData,
    personId: string,
    { setSubmitting, setTouched, setFieldValue }: FormikHelpers<QuoteJourneyFormData>
  ): Promise<QuoteSummaryResponseIAPIResult> => {
    return new Promise((resolve, reject) => {
      let createQuoteRequest;
      if (
        values.registeredKeeper?.id === undefined ||
        values.registeredKeeper?.id === 'registeredKeeper_yes'
      ) {
        // User is a full licence holder or is a learner driver and is the registered keeper of the vehicle
        createQuoteRequest = getCreateQuoteRequestBody(
          values,
          personId,
          referrer,
          referrerChannel
        );
      } else if (values.registeredKeeper?.id === 'registeredKeeper_no') {
        // User is not the registered keeper of the vehicle so we need to add the asset holder to the person object
        createQuoteRequest = getCreateRegisteredKeeperQuoteRequestBody(
          values,
          personId,
          referrer,
          referrerChannel
        );
      } else {
        reject(new Error("Couldn't determine which API call to make"));
      }

      createQuoteMutation
        .mutateAsync(createQuoteRequest || {})
        .then(quote => {
          if (quote.detail?.refusalCheck?.isRefused === false) {
            // The quote is valid! Go to cover length page

            // If the quote is from Mulsanne, show the user the mulsanne confirmation dialog
            if (quote.detail?.underwriterName === UnderWriterName.MULSANNE) {
              setMulsanneDialogOpen(true);
            }

            const inferredProductName = inferProductName(values);

            // Send a quote created gtm event
            TagManager.dataLayer({
              dataLayer: quoteCreatedEvent(
                referrer,
                values.vehicleType?.id,
                inferredProductName,
                quote.detail.price?.total
              ),
            });
            // Send a product details gtm event
            TagManager.dataLayer({
              dataLayer: productDetailsEvent(
                referrer,
                values.vehicleType?.id,
                inferredProductName,
                quote.detail.price?.total
              ),
            });

            // Set the underwriter and product in DocumentContext
            setUnderwriter(quote?.detail?.underwriterName || 'aviva');
            setProduct(inferredProductName);

            // Set the values
            setFieldValue('quoteId', quote.detail.id);
            setFieldValue('product', inferredProductName);
            setFieldValue('underwriter', quote.detail.underwriterName);
            setFieldValue('price', quote.detail.price);
            setFieldValue('coverLength', {
              id: `${quote.detail?.duration?.value} ${quote.detail?.duration?.type}`,
              ...quote.detail.duration,
            });
            setFieldValue('excess', quote.detail.excess);
            setFieldValue(
              'addOns',
              quote.detail.availableAddOns?.map(option => ({
                id: option.type,
                name: getAddonTitle(t, option),
                description: option.description,
                price: option.price?.total,
                checked: false,
              }))
            );

            navigate({
              pathname: `./${quote.detail.id}/${QuoteJourneyRoutes.CoverLength}`,
            });
            setSubmitting(false);
            setTouched({});
            resolve(quote);
          } else {
            // The quote has been refused. Show the refusal dialog
            setSubmitting(false);
            setGlobalError(undefined, {
              title: quote.detail?.refusalCheck?.refusalTitle || undefined,
              message: quote.detail?.refusalCheck?.refusalReason || undefined,
              cta: t('common:errors.dialog.cta_cancel'),
              onClose: () => {
                navigate('/quote');
              },
            });
          }
        })
        .catch(error => {
          const message = noProductAvailableForBrand(referrer.name)
            ? t('quotes:no_product_available_error_message')
            : error.message;
          setSubmitting(false);
          setGlobalError(error, { message });
        });
    });
  };

  const runFraudCheck = async (values: QuoteJourneyFormData) => {
    return new Promise<QuoteSummaryResponseIAPIResult>((resolve, reject) => {
      updateQuoteMutation
        .mutateAsync({
          id: values.quoteId || '',
          runFraudCheck: true,
          blackBoxId: blackboxId,
        })
        .then(quote => {
          if (quote.detail?.refusalCheck?.isRefused === false) {
            // The quote is valid! Return
            resolve(quote);
          } else {
            // The quote has been refused. Show the refusal dialog
            setGlobalError(undefined, {
              title: quote.detail?.refusalCheck?.refusalTitle || undefined,
              message: quote.detail?.refusalCheck?.refusalReason || undefined,
              cta: t('common:errors.dialog.cta_cancel'),
              onClose: () => {
                navigate('/quote');
              },
            });
          }
        })
        .catch(reject);
    });
  };

  const handleSubmit = async (
    values: QuoteJourneyFormData,
    {
      setSubmitting,
      setFieldValue,
      setTouched,
      ...formikHelpers
    }: FormikHelpers<QuoteJourneyFormData>
  ) => {
    try {
      let api = new DayInsureAPI(OpenAPI);

      // SUBMITTED ON REGISTRATION PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.RegistrationSearch) {
        setFieldValue('journeyStartPosition', QuoteJourneyRoutes.RegistrationSearch);

        await api.vehicle
          .getApiV1Vehicle(values.vehicleRegistration)
          .then(response => {
            if (response.detail?.abiCode) {
              setFieldValue('vehicleModel', {
                id: response.detail.abiCode,
                name: response.detail.description,
              });
              setFieldValue('vehicleType', {
                id: response.detail.vehicleType,
                name: response.detail.vehicleType,
              });
              setFieldValue('gearboxType', {
                id: response.detail.transmission,
                name: response.detail.transmission,
              });
              setFieldValue('vehicleMake', {
                id: response.detail.make,
                name: response.detail.make,
              });
              setFieldValue('fuelType', {
                id: response.detail.fuelType,
                name: response.detail.fuelType,
              });
              setFieldValue('yearOfManufacture', {
                id: response.detail.startYear,
                name: response.detail.startYear,
              });

              // Set the vehicle type in document context
              if (response.detail.vehicleType) {
                setAssetType(response.detail?.vehicleType as AssetType);
              }

              if (response.detail.vehicleType === VehicleType.VAN_LCV) {
                setConvertedVanDialogOpen(true);
              }

              navigate({
                pathname: `./${QuoteJourneyRoutes.DriverDetails}`,
                search: location.search,
              });
            } else {
              navigate({
                pathname: `./${QuoteJourneyRoutes.ManualVehicleEntry}`,
                search: location.search,
              });
            }
          })
          .catch(e => {
            if (isApiError(e) && e.status === 404) {
              navigate({
                pathname: `./${QuoteJourneyRoutes.ManualVehicleEntry}`,
              });
            } else {
              setGlobalError(e);
            }
          })
          .finally(() => {
            setSubmitting(false);
            setTouched({});
          });
      }

      // SUBMITTED ON MANUAL VEHICLE ENTRY PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.ManualVehicleEntry) {
        if (values.vehicleType?.id === VehicleType.VAN_LCV) {
          setConvertedVanDialogOpen(true);
        }

        setFieldValue('vehicleAbiCode', values.vehicleModel?.id);

        // Set the vehicle type in document context
        setAssetType(
          vehicleTypeToAssetType(values.vehicleType?.id || VehicleType.MOTOR_CAR)
        );

        navigate({
          pathname: `./${QuoteJourneyRoutes.DriverDetails}`,
        });
        setSubmitting(false);
        setTouched({});
      }

      // SUBMITTED ON DRIVER DETAILS PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.DriverDetails) {
        navigate({
          pathname: `./${QuoteJourneyRoutes.ContactDetails}`,
          search: location.search,
        });
        setSubmitting(false);
        setTouched({});
      }

      // SUBMITTED ON CONTACT DETAILS PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.ContactDetails) {
        let guest;
        if (currentPersonId) {
          // They are signed in as either a full account or a guest
          await api.person.putApiV1Person(
            currentPersonId,
            getUpdateAccountRequestBody(values)
          );
        } else {
          // They are creating the guest account
          guest = await api.person.postApiV1Person(
            getCreateGuestAccountRequestBody(values, referrer)
          );

          if (guest.detail?.accessToken && guest.detail.id && guest.detail.refreshToken) {
            // Create a partial auth user and push it to session.
            sessionStorage.setItem(
              `oidc.user:${process.env.REACT_APP_AUTH_AUTHORITY}:${process.env.REACT_APP_AUTH_CLIENT_ID}`,
              JSON.stringify({
                access_token: guest.detail.accessToken,
                refresh_token: guest.detail.refreshToken,
              })
            );

            // Silently sign in the user. This will kick off automatic token refresh
            await signinSilent()
              .then(user => {
                OpenAPI.TOKEN = user?.access_token;
                api = new DayInsureAPI(OpenAPI);
              })
              .catch(() => {
                throw new Error('Failed to set up guest account');
              });
          } else {
            throw new Error('API did not return access token or id');
          }
        }

        // Send gtm event
        TagManager.dataLayer({ dataLayer: customerIdentifiedEvent(values) });

        // Fetch the id for the request, this will either be the id of the signed in user, or the stored guest id
        const idForRequest = currentPersonId || guest?.detail?.id;
        if (!idForRequest) {
          throw new Error('No person Id found');
        }

        // Check if they are a learner driver
        await api.person
          .getApiV1PersonLicence(idForRequest)
          .then(async licence => {
            const entitlement = licence.detail?.entitlements?.find(
              ent => ent.type === values.vehicleType?.id
            );

            if (
              entitlement &&
              ((values.gearboxType?.id === VehicleTransmissionType.AUTOMATIC &&
                entitlement.automatic === LicenceType.PROVISIONAL) ||
                (values.gearboxType?.id === VehicleTransmissionType.MANUAL &&
                  entitlement.manual === LicenceType.PROVISIONAL))
            ) {
              // We've determined that they have a provisional licence for the car they are insuring
              setFieldValue('isLearner', true);
              navigate({
                pathname: `./${QuoteJourneyRoutes.LearnerDriver}`,
              });
              setSubmitting(false);
              setTouched({});

              if (
                values.gearboxType?.id === VehicleTransmissionType.MANUAL &&
                entitlement.manual === LicenceType.PROVISIONAL &&
                entitlement.automatic === LicenceType.FULL
              ) {
                setAutomaticLicenceDialogOpen(true);
              }
            } else {
              // They have a full licence, therefore we should try to create the quote
              setFieldValue('isLearner', false);
              await createQuote(values, idForRequest, {
                setSubmitting,
                setTouched,
                setFieldValue,
                ...formikHelpers,
              });
            }
          })
          .catch(() => {
            // The licence api has failed, so we want to ask the user if they are a learner
            setManuallyCheckingLicence(true);
          });
      }

      // SUBMITTED ON LEARNER DRIVER PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.LearnerDriver) {
        if (!currentPersonId) {
          throw new Error('No person id found');
        }

        await createQuote(values, currentPersonId, {
          setSubmitting,
          setTouched,
          setFieldValue,
          ...formikHelpers,
        });
      }

      // SUBMITTED ON COVER LENGTH PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.CoverLength) {
        const continueToNextStep = async (addonsAvailable: boolean) => {
          if (addonsAvailable) {
            // There are addons. Go on to the addons page
            setSubmitting(false);
            setTouched({});
            navigate({
              pathname: `/quote/${values.quoteId}/${QuoteJourneyRoutes.Addons}`,
            });
          } else {
            // There are no addons. Run fraud check and go on to acceptance statements
            await runFraudCheck(values).then(() => {
              setSubmitting(false);
              setTouched({});
              navigate({
                pathname: `/quote/${values.quoteId}/${QuoteJourneyRoutes.AcceptanceStatement}`,
              });
            });
          }
        };

        await updateQuoteMutation
          .mutateAsync(getUpdateQuoteRequestBody(values, undefined))
          .then(quote => {
            if (quote.detail?.refusalCheck?.isRefused === false) {
              const currentAddons = values.addOns;
              setFieldValue(
                'addOns',
                quote?.detail?.availableAddOns?.map(option => ({
                  id: option.type,
                  name: getAddonTitle(t, option),
                  description: option.description,
                  price: option.price?.total,
                  checked:
                    currentAddons?.find(addon => addon.id === option.type)?.checked ||
                    false,
                }))
              );
              // The quote is valid! Go to the next step
              continueToNextStep(currentAddons !== undefined && currentAddons.length > 0);
            } else {
              // The quote has been refused. Show the refusal dialog
              setGlobalError(undefined, {
                title: quote.detail?.refusalCheck?.refusalTitle || undefined,
                message: quote.detail?.refusalCheck?.refusalReason || undefined,
                onClose: () => {
                  navigate('/quote');
                },
              });
            }
          })
          .catch(err => {
            if (isApiError(err) && err.status === 400) {
              // If nothing has changed we will receive a 400 error. Continue as normal
              continueToNextStep(values.addOns !== undefined && values.addOns.length > 0);
            } else {
              throw err;
            }
          });
      }

      // SUBMITTED ON COVER ADDONS PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.Addons) {
        const continueToAcceptanceStatement = () => {
          setSubmitting(false);
          setTouched({});
          navigate({
            pathname: `/quote/${values.quoteId}/${QuoteJourneyRoutes.AcceptanceStatement}`,
          });
        };

        // Run fraud check
        await runFraudCheck(values).then(continueToAcceptanceStatement);
      }

      // SUBMITTED ON ACCEPTANCE CRITERIA PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.AcceptanceStatement) {
        await createAcceptanceCriteriaMutation
          .mutateAsync({
            quoteId: values.quoteId,
            agreements: values.acceptanceCriteria?.map(criteria => ({
              type: criteria.type,
              contentHash: criteria.contentHash,
            })),
          })
          .then(() => {
            setSubmitting(false);
            setTouched({});
            navigate({
              pathname: `/quote/${values.quoteId}/${QuoteJourneyRoutes.Review}`,
            });
          });
      }

      // SUBMITTED ON REVIEW PAGE
      if (journeyProgress.formPage === QuoteJourneyRoutes.Review) {
        // Send a checkout reach gtm event
        TagManager.dataLayer({
          dataLayer: quoteCheckoutReachedEvent(
            referrer,
            values.vehicleType?.id,
            inferProductName(values),
            values.price?.total
          ),
        });

        setSubmitting(false);
        setTouched({});
        navigate({
          pathname: `/quote/${values.quoteId}/${QuoteJourneyRoutes.Payment}`,
        });
      }
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleManuallyCheckedLicence = (
    type: LicenceType,
    values: QuoteJourneyFormData,
    {
      setSubmitting,
      setTouched,
      setFieldValue,
      ...formikHelpers
    }: FormikHelpers<QuoteJourneyFormData>
  ) => {
    if (type === LicenceType.PROVISIONAL) {
      // The user has said that they are a learner driver
      navigate({
        pathname: `./${QuoteJourneyRoutes.LearnerDriver}`,
      });
    } else {
      setSubmitting(true);
      // The user has said that they have a full licence, therefore we should attempt to create the quote
      if (!currentPersonId) {
        throw new Error('No person id found');
      }

      createQuote(values, currentPersonId, {
        setSubmitting,
        setTouched,
        setFieldValue,
        ...formikHelpers,
      });
    }
  };

  const getInitialFormData = useCallback(() => {
    const referrersMarketingOptions = referrer.marketingConsent.options.map(option => ({
      id: option.id,
      name: option.name,
      checked: false,
    }));

    if (
      routerState &&
      routerState.returnFromSignInRedirect &&
      routerState.quoteJourneyData &&
      person
    ) {
      return getInitialDataForSignInMidFlow(person, routerState.quoteJourneyData);
    }

    return person && isFullAccount
      ? getInitialDataForSignedInFlow(person, personsMarketingPreferences, referrer)
      : getQuoteJourneyInitialData({
          marketingPreferences: referrersMarketingOptions,
        });
  }, [referrer, routerState, person, isFullAccount, personsMarketingPreferences]);

  // If the user is authenticated we want to make sure we wait for their details to have been fetched
  // before we set up the form
  if (isFullAccount && !person) {
    return <LoadingSpinner centered size="regular" className="mt-6" />;
  }

  return (
    <Formik<QuoteJourneyFormData>
      initialValues={getInitialFormData()}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
    >
      {formikHelpers => (
        <>
          <PageContainer className="pt-6">
            <Stepper
              steps={steps}
              currentStepIndex={journeyProgress.stepIndex}
              currentStepPercentage={journeyProgress.stepProgress}
            />
            <Form>
              <div className="flex gap-6">
                <Outlet />
                {journeyProgress.formPage &&
                  [
                    QuoteJourneyRoutes.ContactDetails,
                    QuoteJourneyRoutes.DriverDetails,
                    QuoteJourneyRoutes.LearnerDriver,
                    QuoteJourneyRoutes.CoverLength,
                    QuoteJourneyRoutes.Addons,
                    QuoteJourneyRoutes.AcceptanceStatement,
                    QuoteJourneyRoutes.Review,
                    QuoteJourneyRoutes.Payment,
                  ].includes(journeyProgress.formPage) && (
                    <QuoteSummaryCard
                      currentPage={journeyProgress.formPage}
                      className="hidden sticky top-6 mt-24 h-max md:block"
                      showReturnLinks
                    />
                  )}
                {journeyProgress.formPage &&
                  [
                    QuoteJourneyRoutes.CoverLength,
                    QuoteJourneyRoutes.Addons,
                    QuoteJourneyRoutes.AcceptanceStatement,
                    QuoteJourneyRoutes.Review,
                    QuoteJourneyRoutes.Payment,
                  ].includes(journeyProgress.formPage) && <QuoteSummaryMobile />}
              </div>
            </Form>
            <LicenceTypeDialog
              open={manuallyCheckingLicence}
              onClose={() => {
                setManuallyCheckingLicence(false);
              }}
              licenceTypeChosen={type => {
                handleManuallyCheckedLicence(type, formikHelpers.values, formikHelpers);
              }}
            />
            <MulsanneDialog
              open={mulsanneDialogOpen}
              onClose={() => {
                setMulsanneDialogOpen(false);
              }}
            />
            <ConvertedVanDialog
              open={convertedVanDialogOpen}
              onClose={handleCloseConertedVanDialog(formikHelpers.setFieldValue)}
            />
            <AutomaticLicenceDailog
              open={automaticLicenceDialogOpen}
              onClose={handleCloseAutomaticLicenceDialog}
            />
          </PageContainer>
          <FetchingQuoteLoader
            show={createQuoteMutation.isLoading}
            product={inferProductName(formikHelpers.values)}
            vehicleType={formikHelpers.values.vehicleType?.id || VehicleType.MOTOR_CAR}
          />
        </>
      )}
    </Formik>
  );
};

export default QuoteJourney;
