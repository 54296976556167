import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, ReactNode } from 'react';
import Icon from '../../DataDisplay/Icon/Icon';

type TooltipProps = {
  /** contents of tooltip */
  children: ReactNode;
  /** aria label to be applied to the button which opens the tooltip, for screenreaders */
  ariaLabel: string;
  /** Orientation of the tooltip popover. Relative opens it relative to the first positioned parent */
  position?: 'left' | 'right' | 'center' | 'relative';
  /** Class names to pass itnto the tooltip panel */
  className?: string;
  /** Tooltip variant */
  variant?: 'primary' | 'secondary';
  /** Id used for testing */
  testId?: string;
};

const Tooltip = ({
  children,
  ariaLabel,
  position = 'right',
  className,
  variant = 'primary',
  testId,
}: TooltipProps): JSX.Element => {
  return (
    <Popover className={clsx({ 'sm:relative': position !== 'relative' })}>
      <Popover.Button
        aria-label={ariaLabel}
        className="inline-flex outline-none"
        data-testid={`${testId}_button`}
      >
        <Icon
          name="info"
          className={clsx(
            { 'text-main-content-1': variant === 'primary' },
            { 'text-popup-content-1': variant === 'secondary' }
          )}
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        appear
      >
        <Popover.Panel
          className={clsx(
            'absolute left-0 z-10 p-4 w-full font-lato text-sm leading-[150%] bg-white rounded-lg border-2 opacity-0 translate-y-1 sm:left-auto sm:p-6 sm:w-56 lg:w-80 border-main-content-5 text-popup-content-1',
            { 'sm:!right-0': position === 'left' },
            { 'sm:!right-1/2 sm:translate-x-1/2': position === 'center' },
            { 'sm:!left-0': position === 'relative' },
            { 'border-popup-content-1': variant === 'secondary' },
            className
          )}
          data-testid={`${testId}_tooltip`}
        >
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

Tooltip.Close = Popover.Button;

export default Tooltip;
