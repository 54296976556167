export const APP_DISCOUNT = 5;
export const APPLE_APP_STORE_LINK = 'https://app.day.insure/99bV/h7ra3tqb';
export const GOOGLE_PLAY_STORE_LINK = 'https://app.day.insure/99bV/h7ra3tqb';

export const DEFAULT_PRIVACY_POLICY_URL =
  'https://www.dayinsure.com/document/dayinsure-privacy-policy/';
export const DEFAULT_TOBA_URL =
  'https://www.dayinsure.com/document/dayinsure-terms-of-business-2/';
export const DEFAULT_IPID_URL =
  'https://www.dayinsure.com/document/insurance-product-information-document/';
export const MULSANNE_PRIVACY_POLICY_URL =
  'https://www.dayinsure.com/document/mulsanne-privacy-policy/';
