/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AmendmentQuoteResponseIAPIResult } from '../models/AmendmentQuoteResponseIAPIResult';
import type { CancellationQuoteIAPIResult } from '../models/CancellationQuoteIAPIResult';
import type { CancelPolicyRequest } from '../models/CancelPolicyRequest';
import type { DurationListIAPIResult } from '../models/DurationListIAPIResult';
import type { GetPolicyDocumentResponseIAPIResult } from '../models/GetPolicyDocumentResponseIAPIResult';
import type { GetPolicyDocumentsResponseIListIAPIResult } from '../models/GetPolicyDocumentsResponseIListIAPIResult';
import type { GetPolicyResponseIAPIResult } from '../models/GetPolicyResponseIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { ResumePurchaseAmendmentQuoteRequest } from '../models/ResumePurchaseAmendmentQuoteRequest';
import type { StringIServiceResult } from '../models/StringIServiceResult';
import type { WebCancelPolicyResponseIAPIResult } from '../models/WebCancelPolicyResponseIAPIResult';
import type { WebCreateAmendQuoteRequest } from '../models/WebCreateAmendQuoteRequest';
import type { WebPurchaseQuoteRequest } from '../models/WebPurchaseQuoteRequest';
import type { WebPurchaseQuoteResponseIAPIResult } from '../models/WebPurchaseQuoteResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PolicyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authorized - Retrieves policy details for a given ID
   * @param id A unique ID for a policy
   * @param policyVersion
   * @returns GetPolicyResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Policy(
    id: string,
    policyVersion?: string,
  ): CancelablePromise<GetPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{id}',
      path: {
        'id': id,
      },
      query: {
        'policyVersion': policyVersion,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Returns a list of policy documents and the means of downloading them
   * @param id A unique ID for a policy
   * @returns GetPolicyDocumentsResponseIListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyDocument(
    id: string,
  ): CancelablePromise<GetPolicyDocumentsResponseIListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{id}/document',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Downloads a policy document given the policy ID and document type
   * @param policyId A unique ID for a policy
   * @param documentType The document type
   * @returns GetPolicyDocumentResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyDocument1(
    policyId: string,
    documentType: string,
  ): CancelablePromise<GetPolicyDocumentResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/document/{documentType}',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Send paper copy of policy document - Flags the policy document to be sent via post to the customer
   * @param policyId An ID representing a policy
   * @param documentType The documentType you want to return
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyDocumentSendPaperCopy(
    policyId: string,
    documentType: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/document/{documentType}/send-paper-copy',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Returns a quote to cancel and refund the given policy.
   * @param policyId An ID representing a policy.
   * @returns CancellationQuoteIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyCancellationQuote(
    policyId: string,
  ): CancelablePromise<CancellationQuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Cancels a policy using the stored cancellation quote if valid.
   * @param policyId An ID representing a policy.
   * @param cancellationQuoteId The id of the stored cancellation quote.
   * @param requestBody
   * @returns WebCancelPolicyResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyCancellationQuoteCancelPolicy(
    policyId: string,
    cancellationQuoteId: string,
    requestBody?: CancelPolicyRequest,
  ): CancelablePromise<WebCancelPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote/{cancellationQuoteId}/cancel-policy',
      path: {
        'policyId': policyId,
        'cancellationQuoteId': cancellationQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }

  /**
   * Authorized - Creates a quote to amend the given policy.
   * @param policyId An ID representing a policy
   * @param requestBody An Object representing the changes you wish to make to a policy
   * @returns AmendmentQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyAmendmentQuote(
    policyId: string,
    requestBody?: WebCreateAmendQuoteRequest,
  ): CancelablePromise<AmendmentQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }

  /**
   * Authorized - Gets a previously generated quote to amend an existing policy.
   * @param policyId An ID representing a policy
   * @param amendmentQuoteId An ID representing the amendment quote
   * @returns AmendmentQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyAmendmentQuote(
    policyId: string,
    amendmentQuoteId: string,
  ): CancelablePromise<AmendmentQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        409: `Conflict`,
      },
    });
  }

  /**
   * Authorized - Allows payment to be taken for the given amendment quote
   * @param policyId An ID representing a policy
   * @param amendmentQuoteId
   * @param requestBody An Object representing the changes you wish to make to a policy
   * @returns WebPurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyAmendmentQuotePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: WebPurchaseQuoteRequest,
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}/purchase',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }

  /**
   * Authorized - Processes 3D secure challenges to complete payment for amendments
   * @param policyId
   * @param amendmentQuoteId REQUIRED
   * @param requestBody REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
   * @returns WebPurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyAmendmentQuoteResumePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: ResumePurchaseAmendmentQuoteRequest,
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}/resume-purchase',
      path: {
        'policyId': policyId,
        'amendmentQuoteId': amendmentQuoteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }

  /**
   * Gets the extendable durations for a polucy
   * @param policyId An ID representing a policy
   * @returns DurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyExtensionDuration(
    policyId: string,
  ): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/extension-duration',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * save policy id in cache to later retrive it and link to an existing confirm account.
   * @param policyId
   * @returns StringIServiceResult Success
   * @throws ApiError
   */
  public postApiV1PolicyCreateSession(
    policyId: string,
  ): CancelablePromise<StringIServiceResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/create-session',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}