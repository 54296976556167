import { FormattedPrice, Icon } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import { QuoteJourneyFormData } from '../../../../helpers/forms/quote';
import QuoteSummaryDialog from '../QuoteSummaryDialog/QuoteSummaryDialog';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { getDiscount, getDiscountValue } from '../../../../helpers/discount/discount';

const QuoteSummaryMobile = () => {
  const { t } = useTranslation('quotes');
  const {
    values: { price, registeredKeeper, excess, underwriter },
  } = useFormikContext<QuoteJourneyFormData>();

  const [breakdownOpen, setBreakdownOpen] = useState(false);

  const isUpdatingQuote = useIsMutating(['update-quote']);
  const isCreatingAmendQuote = useIsMutating(['amend-quote']);
  const isLoading = isUpdatingQuote || isCreatingAmendQuote;

  // adds margin to the footer so the quote summary does not cover it's content
  useEffectOnce(() => {
    const footer = document.querySelector(`footer`);
    if (footer) {
      footer.classList.add(`mb-28`, `md:mb-0`);
    }
    return () => {
      if (footer) {
        footer.classList.remove(`mb-28`, `md:mb-0`);
      }
    };
  });

  const discount = getDiscount(price);
  const isLearner = !!registeredKeeper;

  return (
    <>
      {price && (
        <>
          <div className="flex fixed bottom-0  z-30 flex-col items-center pt-4 pb-6 -mx-6 w-full text-white rounded-t-lg md:hidden bg-summary-1">
            <span className="flex gap-2 items-center mb-2 text-sm leading-3 text-white/60">
              <Icon name="card" />
              {t('summary.price')}
            </span>
            <div className="flex gap-3 items-center mb-2">
              {discount && (
                <span
                  className={clsx(
                    'flex relative items-center text-xl leading-9 opacity-60'
                  )}
                  id="summary-discount-mobile"
                >
                  <FormattedPrice
                    price={getDiscountValue(price)}
                    size="BASE"
                    lineThrough
                  />
                </span>
              )}
              <span
                className={clsx('flex relative items-center text-xl leading-9', {
                  'text-white/40': isLoading,
                })}
                id="summary-price-details-mobile"
              >
                <FormattedPrice price={price?.total} />
              </span>
            </div>
            <span className="inline-block px-20 mb-2 text-sm leading-4 text-center">
              {t('summary.fees')}
            </span>
            <button
              aria-label={t('summary.dialog.breakdown_title')}
              type="button"
              onClick={() => {
                setBreakdownOpen(true);
              }}
              className="flex gap-1 items-center"
            >
              <Icon name="info" />
              <span className="text-sm">{t('summary.price_breakdown')}</span>
            </button>
          </div>
          {price && (
            <QuoteSummaryDialog
              price={price}
              open={breakdownOpen}
              isLearner={isLearner}
              excess={excess}
              underwriter={underwriter}
              onClose={() => {
                setBreakdownOpen(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuoteSummaryMobile;
