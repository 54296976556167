import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, DayInsureAPI, OpenAPI, QuoteIAPIResult } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import query from './queryKeys';

const useQuoteQuery = (id: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getQuote = () => {
    if (id) {
      const api = new DayInsureAPI(OpenAPI);
      return api.quote.getApiV1Quote(id || '');
    }
    throw new Error('No quote ID');
  };

  return useQuery<QuoteIAPIResult, ApiError>(query.quoteDetails(id || ''), getQuote, {
    enabled: id !== undefined,
    onError: err => {
      // Don't show a global error on 401. We want to handle this manually.
      if (!(err?.status === 401)) {
        setGlobalError(err);
      }
    },
  });
};

export default useQuoteQuery;
