export const brands = [
  'Vauxhall',
  'Stellantis',
  'Halifax',
  'OneAnswer',
  'Hyperjar',
  'RedDrivingSchool',
  'DrivingTestSuccess',
  'Principal',
  'Comfort',
];

const toLowerCase = (brand: string) => brand.toLowerCase();

const noProductAvailableForBrand = (referrerName: string): boolean => {
  return brands.map(toLowerCase).includes(referrerName.toLowerCase());
};

export default noProductAvailableForBrand;
