import clsx from 'clsx';
import { getDecimal, getInteger } from './FormattedPrice.utils';

type FontSizeClassName =
  | 'text-xs'
  | 'text-sm'
  | 'text-md'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | string;

type Size = 'BASE' | 'LG' | 'XL' | '2XL';

type FontSizeClasses = {
  primary: string;
  secondary: string;
};

const sizeToCLassNameMap: Record<Size, FontSizeClasses> = {
  BASE: { primary: 'text-base', secondary: 'text-sm md:text-xs' },
  LG: { primary: 'text-lg', secondary: 'text-sm' },
  XL: { primary: 'text-xl', secondary: 'text-base' },
  '2XL': { primary: 'text-2xl', secondary: 'text-lg' },
};

type OffsetValues = {
  primaryOffset: number;
  secondaryOffset: number;
};

const sizeToUnderlineOffset: Record<Size, OffsetValues> = {
  BASE: { primaryOffset: -5, secondaryOffset: -4 },
  LG: { primaryOffset: -7, secondaryOffset: -5 },
  XL: { primaryOffset: -9, secondaryOffset: -6 },
  '2XL': { primaryOffset: -14, secondaryOffset: -8 },
};

const getOffsetValues = (size: Size, lineThrough?: boolean): OffsetValues => {
  if (!lineThrough) {
    return {
      primaryOffset: 0,
      secondaryOffset: 0,
    };
  }

  return sizeToUnderlineOffset[size];
};

interface Props {
  price?: number;
  size?: Size;
  primaryFontSize?: FontSizeClassName;
  secondaryFontSize?: FontSizeClassName;

  lineThrough?: boolean;
  prefix?: string;
  sufix?: string;
  className?: string;
}

const FormattedPrice = ({
  price,
  size = 'XL',
  primaryFontSize,
  secondaryFontSize,
  lineThrough,
  prefix,
  sufix,
  className,
}: Props) => {
  if (!price) {
    return null;
  }

  const { primary, secondary } = sizeToCLassNameMap[size];
  const { primaryOffset, secondaryOffset } = getOffsetValues(size, lineThrough);

  const pClasses = clsx([
    primaryFontSize || primary,
    'inline',
    lineThrough && (size === '2XL' ? 'strike-throuh-2' : 'strike-throuh-1'),
  ]);

  const spanClasses = clsx([
    secondaryFontSize || secondary,
    lineThrough && 'strike-throuh-1',
  ]);

  if (price === 0) {
    return <p className={pClasses}>£0</p>;
  }

  return (
    <div className={clsx('inline', className)}>
      <p className={pClasses} style={{ textUnderlineOffset: primaryOffset }}>
        {prefix}£{getInteger(price)}
      </p>
      <span className={spanClasses} style={{ textUnderlineOffset: secondaryOffset }}>
        .{getDecimal(price)}
        {sufix}
      </span>
    </div>
  );
};

export default FormattedPrice;
